@import "./variables.scss";

.footer {
    background-color: $black-secondary;
    width: 100%;
}
.container-footer {
    width: 100%;
    margin: auto;
    padding: 1.2rem;
}
.container-footer p {
    color: $white;
    font-weight: 500;
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
}
.icon-social {
    width: 28px;
    height: 28px;
    color: $white !important;
    margin-right: 0.7rem;
    margin-left: 0.5rem;
}
.icon-social:hover {
    opacity: 0.7;
}
.footer-col {
    width: 30%;
    padding: 0 1.4rem;
    border-right: 2px solid $white;
}
.footer-col .list-items {
    display: flex;
    flex-wrap: wrap;
}
.footer-col h4 {
    font-size: 1.6rem;
    color: $white;
    text-transform: capitalize;
    margin-bottom: 1.1rem;
    margin-left: 0.7rem;
}
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.row > :last-child {
    border: 0;
}
.social-links {
    margin-top: 1.4rem;
}
.social-links a:nth-child(5),
.social-links a:nth-child(6) {
    visibility: hidden;
}
.social-contacts {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-top: 1.2rem;
}
.social-contacts a {
    list-style: none;
    text-decoration: none;
    color: $white;
    padding: 0.5rem;
    font-size: 1rem;
}
.icon-contacts {
    width: 28px;
    height: 28px;
    margin-right: 1rem;
}
.social-contacts a:hover {
    color: $white !important;
}
.list-items li {
    flex: 1 1 calc(35% - 20px);
    height: 40px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.icon-address {
    width: 75px;
    height: 75px;
    border-radius: 5px;
    padding: 0.3rem;
}
.footer-col .list-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.list-address li {
    list-style: none;
    color: $white;
    font-size: 1rem;
}
@media (max-width: 900px) {
    .footer-col {
        width: 70%;
        margin-bottom: 1.1rem;
        border-right: 0;
    }
    .footer-col h4 {
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 0.3rem;
        margin-left: 0 !important;
    }
    .title-categoria {
        display: none;
    }
    .social-links {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .social-contacts {
        display: none;
    }
    .container-footer p {
        margin-top: 0;
    }
    .list-items {
        display: none !important;
    }
    .list-address li {
        padding: 0.2rem;
        margin: 0;
    }
    .icon-contacts {
        display: none;
    }
    .icon-address {
        display: none !important;
        padding: 0;
        margin: 0;
    }
    .container-footer p {
        margin: 0;
    }
    .social-links a:nth-child(5),
    .social-links a:nth-child(6) {
        visibility: visible;
    }
}
