@import "./variables.scss";
.container-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 9px 18px rgba(185, 185, 185, 0.25) !important;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    height: 500px;
}
.logo {
    height: 3.4rem;
}

.cirles-content {
    position: relative;
}
.circle-tablet {
    position: absolute;
    bottom: 0;
    background: url("../images/tablet.jpg") no-repeat left;
    background-size: cover;
    clip-path: circle(50%);
    width: 130px;
    height: 130px;
}
.circle-notebook {
    position: absolute;
    background: url("../images/notebook.jpg") no-repeat center;
    clip-path: circle(50%);
    width: 130px;
    height: 130px;
    background-size: cover;
    top: 0;
    margin-left: -3rem;
}
.circle-computer {
    width: 520px;
    height: 420px;
    background: url("../images/computer.png") no-repeat center;
    background-size: cover;
}
.content-header h1 {
    color: #444444;
    font-weight: 800;
    font-size: 2.7rem;
    @media (max-width: 400px) {
        font-size: 2.3rem;
    }
}
.list-equipaments ul li {
    list-style: none;
    font-size: 1.1rem;
}
.title-list {
    border-bottom: 2px solid #bb1015;
    width: 15rem;
    padding-left: 1rem;
}
#brand {
    padding: 1rem 2rem;
}
@media (max-width: 1000px) {
    .col-circles {
        display: none;
    }
}
@media (max-width: 400px) {
    .list-equipaments {
        flex-direction: column;
    }
}
