$black: #000;
$black-secondary:#100E15;
$white: #fff;
$white-secondary: #fafafa;
$gray-one:#454545;
$gray-two: #6B6B6B;
$gray-three: #848484;
$gray-four: #ccc;
$red-one: #b90000;
$red-two: #c70000;
$red-three: #a10000;
$red-four: #7a0000;
$blue:#3498db;
$green: #4db457;
$color-box-shadow:rgba(0, 0, 0, 0.25);