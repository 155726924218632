*,
*::before *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none !important;
  list-style: none !important;
  font-family: "Mulish", sans-serif;
}
.container-layout {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 14px;
  background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 12px;
}
.container-main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
