@import "./variables.scss";
.container-home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -2.7rem;
}
.card-shadow {
  box-shadow: 1px 13px 20px rgba(0, 0, 0, 0.25);
}
.form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  @media (max-width: 620px) {
    padding: 0.5rem;
  }
}
.form form label {
  font-size: 1.1rem;
  color: $gray-one !important;
  font-weight: 400;
  padding: 0.3rem 0;
  @media (max-width: 620px) {
    font-size: 1rem;
  }
}
.form form input,
.form form textarea {
  border: 1px solid #ccc !important;
  border-radius: 7px;
  padding: 0.4rem;
  outline: none;
  @media (max-width: 468px) {
    padding: 0.3rem;
  }
}
.form form input:focus,
.form form textarea:focus {
  border: 2px solid #2e86de !important;
}
.col-called .btn-send,
.col-rent .btn-send {
  border: 0;
  background-color: $green;
  color: $white;
  text-align: center;
  width: 200px;
  padding: 0.6rem;
  font-size: 1.1rem;
  border-radius: 10px;
  font-weight: bold;
  @media (max-width: 468px) {
    padding: 0.3rem;
    font-size: 1rem;
  }
}
.col-called .btn-send:hover,
.col-rent .btn-send:hover {
  opacity: 0.7;
  cursor: pointer;
  transition: 0.3s;
}
.text-form {
  text-align: center;
  color: #404040;
  font-size: 1.1rem;
}
.card-header {
  background-color: #bb1015;
}
.message {
  color: #0f0f0f99;
  font-size: 0.9rem;
  text-align: justify;
}
.col-called,
.col-rent {
  width: 740px;

  @media (max-width: 1500px) {
    width: 600px;
  }
  @media (max-width: 1200px) {
    width: 600px;
    padding: 1rem 0;
  }
  @media (max-width: 620px) {
    width: 460px;
  }
  @media (max-width: 468px) {
    width: 350px;
  }
  @media (max-width: 320px) {
    width: 300px;
  }
}

.card {
  height: 775px;
}
